// src/lib/ghost.ts
import GhostContentAPI from "@tryghost/content-api";
import * as GhostTypes from "@tryghost/content-api";

export const ghost = new GhostContentAPI({
  url: "https://find-ai.ghost.io", // Replace with your Ghost CMS URL
  key: "4fc859cc762380ff11197e7663", // Replace with your Content API Key
  version: "v5.0",
  // use native fetch instead of axios to avoid Node 22 issues
  makeRequest: ({ url, method, params, headers }) => {
    const apiUrl = new URL(url);

    Object.keys(params).forEach((key) => {
      apiUrl.searchParams.set(key, params[key]);
    });

    return fetch(apiUrl.toString(), { method, headers }).then(async (res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return { data: await res.json() };
    });
  },
});

// Use types from the GhostTypes namespace
export type GhostPost = GhostTypes.PostOrPage;
export type GhostPosts = GhostTypes.PostsOrPages;
