import { ghost } from "$lib/ghost";
import type { ParamMatcher } from "@sveltejs/kit";
import type { Tag } from "@tryghost/content-api";

// determine if /blog/[tag] or /blog/[slug] should be used
// because custom SvelteKit param matcher can't be async, we fetch tags on init
// and then update them after every request (stale while revalidate)
let tags: Tag[] = [];

const fetchTags = () => {
  ghost.tags.browse({ filter: "visibility:public" }).then((newTags) => {
    tags = newTags;
  });
};
fetchTags();

export const match = ((param: string) => {
  fetchTags();
  return tags.some((tag) => tag.slug === param);
}) satisfies ParamMatcher;
